import { TAnalyticProductOptions } from './types';

export class AnalyticProduct {
    id = '';
    name = '';
    price = '';
    brand = '';
    category = '';
    variant = '';
    position = '';
    listName = '';
    currency = '';

    constructor({ id, name, price, brand, category, variant, position, listName, currency }: TAnalyticProductOptions) {
        this.id = id;
        this.name = name;
        this.price = price;
        this.brand = brand;
        this.category = category;
        this.variant = variant;
        this.position = position;
        this.listName = listName;
        this.currency = currency;
    }
}
