import {
    BEGAN_CHECKOUT,
    CART_DISPLAYED,
    CHECKOUT_OPTIONS_SUBMITED,
    PRODUCTS_DISPLAYED,
    PRODUCT_ADDED_TO_CART,
    PRODUCT_CLICKED,
    PRODUCT_DISPLAYED,
    PRODUCT_REMOVED_FROM_CART,
    PURCHASED
} from '@frontstoreRwd/events/events_names';

export const ANALYTIC_EVENTS_LIST = [
    PRODUCT_CLICKED,
    PRODUCT_ADDED_TO_CART,
    PRODUCT_REMOVED_FROM_CART,
    PRODUCT_DISPLAYED,
    PRODUCTS_DISPLAYED,
    BEGAN_CHECKOUT,
    CART_DISPLAYED,
    CHECKOUT_OPTIONS_SUBMITED,
    PURCHASED
] as const;
