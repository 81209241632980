import { ANALYTICS_CONFIG_ELEMENT_ID } from './constants';
import { AnalyticManager } from './classes/manager';
import { GoogleAnalyticsV4Initializer } from './services/google_analytics_v4/initializer';
import { TAnalyticsOptions } from './types';
import { getJSONFromScriptTag } from '@core/dom_utilities/get';

export class AnalyticsInitializer {
    private _analyticsOptions: TAnalyticsOptions | undefined;
    private _analyticsManager: AnalyticManager;

    constructor() {
        this._analyticsOptions = getJSONFromScriptTag(ANALYTICS_CONFIG_ELEMENT_ID) as TAnalyticsOptions;
        this._analyticsManager = new AnalyticManager();
    }

    public async init(): Promise<undefined> {
        if (!this._analyticsOptions) {
            return;
        }

        new GoogleAnalyticsV4Initializer(this._analyticsManager, this._analyticsOptions.clientGoogleAnalyticsV4).init();
    }
}
